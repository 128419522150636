

































































































































import axios from "axios";
import moment from "moment-timezone";
import { Component, Vue } from "vue-property-decorator";
import { Get, Call } from "vuex-pathify";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import suppliersService from "../../services/suppliers.service";
import { SUBDOMAIN_MAX_LENGTH, subdomain, badSubdomain } from "../../services/validators.service";

import { AppSettingsModel, CountryModel, CurrencyType, UserModel } from "@/@types/model";

import { DEFAULT_CURRENCIES, CUSTOMER_URL } from "../../config";
import { TranslateResult } from "vue-i18n";

const [protocol, domain] = CUSTOMER_URL.split("{subdomain}");

type Currency = { label: string; sign: string };
type Language = { code: string; label: string };
type TimeZone = { offset: string; label: string };
type Settings = {
  country: string;
  timeFormat: number;
  timeZone: TimeZone;
  firstDayOfWeek: "mon" | "sun";
  defaultCurrency: Currency;
  defaultLanguage: string;
  alternativeLanguages: string[];
  subdomain: string;
};

const SettingsSchema = (): Settings => ({
  country: "",
  timeFormat: 24,
  timeZone: { offset: "", label: "" },
  firstDayOfWeek: "mon",
  defaultCurrency: { label: "", sign: "" },
  defaultLanguage: "",
  alternativeLanguages: [],
  subdomain: "",
});

@Component({
  name: "FirstSettings",
  mixins: [validationMixin],
  middleware: "check-not-first-config",
})
export default class FirstSettings extends Vue {
  readonly SUBDOMAIN_MAX_LENGTH: number = SUBDOMAIN_MAX_LENGTH;

  step: number = 1;
  timeZones: TimeZone[] = [];
  currencies: Currency[] = DEFAULT_CURRENCIES;

  readonly protocol: string = protocol;
  readonly domain: string = domain;

  isLoading: boolean = true;
  form: Settings = SettingsSchema();
  isBusy: boolean = false;

  @Get("auth/user")
  readonly user!: UserModel;

  @Get("countries/countries")
  readonly countries!: CountryModel[];

  @Get("app/settings")
  readonly appSettings!: AppSettingsModel;

  @Get("countries/languagesForCountry")
  readonly languagesForCountry!: (_payload: string) => Language[];

  @Call("app/updateSettings")
  readonly updateAppSettings!: (_payload: Partial<AppSettingsModel>) => void;

  validations() {
    return {
      form: {
        country: { required },
        timeZone: { required },
        defaultCurrency: { required },
        defaultLanguage: { required },
        subdomain: { required, subdomain, badSubdomain },
      },
    };
  }

  metaInfo() {
    return { title: this.$t("Navigation.Settings") };
  }

  get languages() {
    const { country } = this.form;
    return this.languagesForCountry(country);
  }

  get isRequired() {
    return (path: string) => {
      if (!this.$v.form[path]!.$dirty) return "";
      if (!this.$v.form[path]!.required) return this.$t("Validation.FieldRequired");
      return "";
    };
  }

  get subdomainErrors() {
    const result: TranslateResult[] = [];
    if (!this.$v.form.subdomain!.$dirty) return [];
    if (!this.$v.form.subdomain!.required) result.push(this.$t("Validation.FieldRequired"));
    if (!this.$v.form.subdomain!.subdomain) result.push(this.$t("Validation.SubdomainHelper"));
    if (!this.$v.form.subdomain!.badSubdomain)
      result.push(this.$t("Validation.IncorrectSubdomain"));
    return result;
  }

  async created() {
    this.timeZones = moment.tz.names().map((zone) => ({
      offset: moment.tz(zone).format("Z"),
      label: "(GMT " + moment.tz(zone).format("Z") + ") " + zone,
    }));

    const { country, timeZone } = await this.getGeolocationData();

    if (country && country.alpha3Code) {
      this.form.country = country.alpha3Code;
    }
    if (country && country.currencies) {
      const currencies = country.currencies.filter(
        (c) => c.code && c.symbol,
      ) as Required<CurrencyType>[];
      const defaultCurrency = currencies.length > 0 ? currencies[0] : { code: "USD", symbol: "$" };
      const { code: label, symbol: sign } = defaultCurrency;

      const isDuplicated = this.currencies.findIndex((c) => c.sign === sign);
      if (isDuplicated == -1) this.currencies.push({ label, sign });

      this.form.defaultCurrency = { label, sign };
    }
    if (country && country.languages) {
      const { iso639_1: code } = country.languages[0];
      const isCheck = this.languages.findIndex((l) => l.code === code);

      if (isCheck !== -1) this.form.defaultLanguage = code || "en";
    }

    this.form.timeZone = timeZone;

    this.form.subdomain = this.appSettings.subdomain;
  }

  async getGeolocationData() {
    let country = null;
    let timeZone = null;

    this.isLoading = true;

    try {
      const { data } = await axios.get(`https://ipinfo.io?token=306257bd3aff41`);
      country = this.countries.find((c) => c.alpha2Code === data.country);
      timeZone = data.timezone;
    } catch (err) {
      console.error(err);
      timeZone = moment.tz.guess();
    } finally {
      this.isLoading = false;
    }

    timeZone = {
      offset: moment.tz(timeZone).format("Z"),
      label: "(GMT " + moment.tz(timeZone).format("Z") + ") " + timeZone,
    };

    return { country, timeZone };
  }

  async save() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const { currentSupplier } = this.user || {};
    if (!currentSupplier) return;

    const supplierId = currentSupplier.id;

    this.isBusy = true;

    const subdomain = this.form.subdomain.toLowerCase();

    try {
      await suppliersService.saveFields(
        supplierId,
        {
          regionSettings: {
            country: this.form.country,
            timeFormat: this.form.timeFormat,
            timeZone: this.form.timeZone,
            firstDayOfWeek: this.form.firstDayOfWeek,
          },
          defaultCurrency: this.form.defaultCurrency,
          defaultLanguage: this.form.defaultLanguage,
          alternativeLanguages: [this.form.defaultLanguage],
          subdomain,
          currencyPosition: "RS",
        },
        { query: { source: "settings.first" } },
      );

      this.updateAppSettings({
        isFirstConfig: false,

        country: this.form.country,
        timeFormat: this.form.timeFormat,
        defaultCurrency: this.form.defaultCurrency.sign,
        defaultLanguage: this.form.defaultLanguage,
        alternativeLanguages: [this.form.defaultLanguage],
        subdomain,
      });

      this.$router.push({ name: "dashboard" });
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
